// We import our styles here, webpack will do the rest
import styles from '../scss/app.scss'

// lazy sizes for lazy loading images
import Lazysizes from 'lazysizes'
import 'picturefill'

// Our own helpers
import './helpers/objectfitFallback'
import './helpers/iePolyfills'

// Our own components
import './components/toggle'
import './components/form'


// Window onload (after all dom and images are loaded completely)
window.onload = function() {

  // Page has loaded, remove loading class
  document.getElementsByTagName('body')[0].classList.remove('loading')


  document.querySelector('#language-toggle').onclick = function showLanguageDropdown() {
    document.getElementById("language-dropdown").classList.toggle("hidden");
  }

  //Outside click
  window.onclick = function(event) {
    if (!event.target.matches('#language-toggle')) {
      var dropdown = document.querySelector("#language-dropdown");
      if (!dropdown.classList.contains('hidden')) {
        dropdown.classList.add('hidden');
      }
    }
  }

  // Responsive tables
  // wrap tables in wysiwyg in a div for styling
  const tables = document.querySelectorAll('.wysiwyg table')
  if (tables.length) {
    if (!('length' in tables)) tables = [tables]
      for (let i = 0; i < tables.length; i += 1) {
        const table = tables[i]

        const div = document.createElement('div')
        div.className = 'table-container'

        table.parentNode.insertBefore(div, table)
        table.parentNode.removeChild(table)
        div.appendChild(table)
      }
    }
  }

//ON SCROLL ANIMATION 
function isInViewport(element) {
    // Get the bounding client rectangle position in the viewport
    var bounding = element.getBoundingClientRect();

    //Using vh
    var scrollOffset = (element.hasAttribute("data-scroll-offset")) ? document.documentElement.clientHeight * parseFloat(element.getAttribute('data-scroll-offset')) : 0;
    // Checking part. Here the code checks if it's *fully* visible
    // Edit this part if you just want a partial visibility
    if (bounding.top <= scrollOffset) {
      return true;
    } else {
      return false;
    }
  }

// Get the an HTML element
var elements = document.querySelectorAll('.animate-on-scroll');

for (let i = 0; i < elements.length; i += 1) {
  if (isInViewport(elements[i]) && !elements[i].classList.contains('in-view')) {
    elements[i].classList.add('in-view')
  }
}

window.addEventListener('scroll', function (event) {
  for (let i = 0; i < elements.length; i += 1) {
    if (isInViewport(elements[i]) && !elements[i].classList.contains('in-view')) {
      elements[i].classList.add('in-view')
    }
  }
}, false);