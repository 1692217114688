// assign HTMLCollection with parents of images with objectFit to variable
const toggleContainer = document.querySelectorAll('[data-toggle-container]')

// Loop through HTMLCollection
for (let i = 0; i < toggleContainer.length; i++) {
  const thisToggleContainer = toggleContainer[i]
  const toggle = thisToggleContainer.querySelector('[data-toggle]')

  if (toggle !== null) {
    toggle.onclick = function(e) {
      e.preventDefault();
      const toggleItems = toggleContainer[i].querySelectorAll(
        '[data-toggle-class]',
      )

      for (let j = 0; j < toggleItems.length; j++) {
        const toggleClass = toggleItems[j].getAttribute('data-toggle-class')

        if (toggleItems[j].classList) {
          toggleItems[j].classList.toggle(toggleClass)
        } else {
          var classes = toggleItems[j].className.split(' ')
          var existingIndex = classes.indexOf(toggleClass)

          if (existingIndex >= 0) classes.splice(existingIndex, 1)
          else classes.push(toggleClass)

          toggleItems[j].className = classes.join(' ')
        }
      }

      // if toggle text is found, toggle the text
      if (toggle.querySelector('[data-toggle-text]') !== null) {
        const toggleText = toggle.querySelector('[data-toggle-text]')
        const originalText = toggleText.innerHTML
        const newText = toggleText.getAttribute('data-toggle-text')

        toggleText.innerHTML = newText
        toggleText.setAttribute('data-toggle-text', originalText)
      }

      const toggleHeightItems = toggleContainer[i].querySelectorAll(
        '[data-toggle-height]',
      )

      for (let h = 0; h < toggleHeightItems.length; h++) {
        const toggleTargetIndex = toggleHeightItems[h].getAttribute('data-toggle-height')
        const toggleTarget = thisToggleContainer.querySelector(`[data-toggle-height-target="${toggleTargetIndex}"]`)
        const toggleTargetHeight = `${toggleTarget.getBoundingClientRect().height}px`
        const openClass = 'is-open';

        if (toggleHeightItems[h].classList.contains('is-open')) {
          toggleHeightItems[h].classList.remove('is-open');
          toggleHeightItems[h].style.height = '0px';
        } else {
          toggleHeightItems[h].style.height = toggleTargetHeight;
          toggleHeightItems[h].classList.add('is-open');
        }

      }


    }
  }
}
